.netw-filter {
  position: relative;
  padding: 1.5em 1.25em;
  margin-bottom: 2.5em;
  margin-top: 2.5em;

  @include media-tablet {
    margin-bottom: 1.25em; }

  @include media-desktop {
    padding-right: 0;
    padding-left: 0;
    padding-top: 2.5em;
    box-shadow: none; }

  label {
    cursor: pointer; }

  hr {
    height: 0.0625em;
    margin: 1.5em 0;
    border: none;
    background-color: $grey-c0; }

  &__reset-btn {
    width: 100%;
    font-weight: 400;

    svg {
      margin-left: 0.625rem; } }

  &__close {
    position: absolute;
    right: 1.25em;
    top: 1.8125em;
    width: 1.25em;
    height: 1.25em;
    transform: rotate(45deg);
    border: none;
    background-color: transparent;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 9999;
      background-color: $dark-grey; }

    &::before {
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 1em;
      height: 2px; }

    &::after {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      height: 1em;
      width: 2px; }

    @include media-desktop {
      display: none; } }

  &__title-wrapper {
    @include media-desktop {
      display: grid;
      grid-template-columns: 1fr auto;
      column-gap: 1rem;
      align-items: center;
      margin-bottom: 1em; }

    .btn {
      display: none;

      @include media-desktop {
        display: inline-flex;
        padding: 0 0.75rem;
        min-width: 0; } }

    & + hr {
      display: none;

      @include media-desktop {
        display: block; } } }

  &__title {
    @include h24;
    margin-bottom: 1em;

    @include media-desktop {
      margin-bottom: 0; } }

  &__filter {
    &-body {
      display: flex;
      flex-direction: column;
      row-gap: 0.75em;

      @include media-tablet {
        flex-direction: row;
        column-gap: 2.5em; }

      @include media-desktop {
        flex-direction: column; } }

    &-item {
      @include fs14;
      margin-right: 0.5em;

      @include media-desktop {
        margin-right: 0; }

      label {
        display: flex;
        column-gap: 1em;
        padding: 0.5em 0;
        color: $dark-grey; }

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: auto;
        min-width: 1.7272em;
        padding: 0.1818em;
        border-radius: 4px;
        font-size: 0.7857em;
        font-weight: 800;
        color: $grey-c0;
        background-color: #fff; }

      input:checked + label {
        color: $color-red;

        span {
          background-color: $color-red;
          color: #fff; } }

      input:focus + label {
        outline: 1px solud black; } } }

  &__country {
    select {
      @include fs14;
      width: 100%;
      appearance: none;
      border: 1px solid $grey-e5;
      border-radius: 0.2857em;
      background-color: white; } }

  &__main {
    margin-bottom: 1.5em;

    &>hr {
      display: none; } }

  &__group {
    padding: 0.25em 0;

    @include media-tablet {
      grid-column: auto / span 1; }

    &-header {
      @include fs18;
      position: relative;
      padding: 0.25em 0;
      font-weight: 800;
      cursor: pointer; }

    &-body {
      display: grid;
      row-gap: 0.75em;
      padding-top: 1em;
      overflow: hidden;
      transition: all 0.3s ease-out; }

    &-arrow {
      position: absolute;
      right: 0.5em;
      top: 0;
      margin-top: 0.5em;
      display: inline-block;
      width: 1em;
      height: 1em;
      transform: rotate(45deg);
      transform-origin: 50% 50%;
      transition: all 0.2s ease-out;

      &::before,
      &::after {
        content: "";
        top: 0;
        bottom: 0;
        position: absolute;
        background-color: $color-red;
        border-radius: 1.25em; }

      &::before {
        width: 2px;
        left: 0;
        top: 0;
        bottom: 0; }

      &::after {
        height: 2px;
        left: 0;
        right: 0;
        bottom: 0; } }

    &--closed {
      .netw-filter__group-body {
        padding-top: 0;
        height: 0; }

      .netw-filter__group-arrow {
        transform: rotate(225deg);
        margin-top: 0; } } }

  &__subgroup {
    @extend .netw-filter__group;

    span {
      padding: 0.1818em;
      min-width: 1.7272em;
      width: auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.7272em;
      font-size: 0.8461em;
      line-height: 1.3636em;
      color: $grey-c0;
      font-weight: 800;
      background-color: white;
      border-radius: 0.3636em; }

    &-body {
      @extend .netw-filter__group-body;
      padding-left: 2em;
      overflow: hidden;
      transition: all 0.3s ease-out; }

    &-header {
      @include fs14;
      display: flex;
      align-items: center;
      font-weight: 800;
      cursor: pointer; }

    &-arrow {
      position: relative;
      display: inline-block;
      width: 0.5em;
      height: 0.5em;
      margin-left: 1em;
      transform: rotate(45deg);
      transform-origin: 50% 50%;
      transition: all 0.2s ease-out;

      &::before,
      &::after {
        content: "";
        top: 0;
        bottom: 0;
        position: absolute;
        background-color: $color-red;
        border-radius: 1.25em; }

      &::before {
        width: 1px;
        left: 0;
        top: 0;
        bottom: 0; }

      &::after {
        height: 1px;
        left: 0;
        right: 0;
        bottom: 0; } }

    &--closed {
      span {
        padding: 0.1818em;
        min-width: 1.7272em;
        width: auto;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.7272em;
        font-size: 0.8461em;
        line-height: 1.3636em;
        color: $grey-c0;
        font-weight: 800;
        background-color: white;
        border-radius: 0.3636em; }

      .netw-filter__subgroup-arrow {
        transform: rotate(225deg); }

      .netw-filter__subgroup-body {
        height: 0;
        padding-top: 0; } } }

  &__submit-btn {
    @extend .netw__filter-btn;
    width: 100%;
    margin-top: 1.7142em;

    @include media-desktop {
      display: block; }

    &::after {
      display: none; } } }

.netw-filter__group-item,
.netw-filter__subgroup-item {
  font-size: 0.8125em;
  line-height: 1.3;

  label {
    display: flex;
    align-items: center;
    padding: 0.5em 0;

    span {
      padding: 0.1818em;
      min-width: 1.7272em;
      width: auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.7272em;
      font-size: 0.8461em;
      line-height: 1.3636em;
      color: $grey-c0;
      font-weight: 800;
      background-color: white;
      border-radius: 0.3636em; }

    &:hover {
      color: $color-red; } }

  input:checked + label {
    color: $color-red;

    span {
      background-color: $color-red;
      color: white; } } }

// * стили для адаптивной версии

.netw-filter--adaptive {
  &.netw-filter {
    position: relative;
    padding: 1.5em 1.25em;
    margin-bottom: 2.5em;
    margin-top: 2.5em;
    box-shadow: 0 0.25em 2.5em rgba(0, 0, 0, 0.15);
    border-radius: 0.5em;

    @include media-tablet {
      margin-bottom: 1.25em; }

    @include media-desktop {
      padding-right: 0;
      padding-left: 0;
      padding-top: 0;
      margin-top: 0;
      box-shadow: none; }

    &--closed {
      height: 0;
      margin-top: 0;
      padding: 0;
      overflow: hidden;

      @include media-desktop {
        height: auto; } }

    .netw-filter {
      &__main {
        @include media-tablet {
          position: relative;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 3em; }

        @include media-desktop {
          display: block; }

        &::after {
          @include media-tablet {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            width: 1px;
            background-color: $grey-c0; }

          @include media-desktop {
            display: none; } } } } } }
