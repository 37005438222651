.header--index {
  display: block;
  height: auto;
  z-index: 0;
  .header {
    &__main {
      // margin-top: 2.5em

      @include media-tablet {
        min-height: 37.375em;
 }        // margin-top: 3.625em

      @include media-tablet-h {
        display: grid;
        grid-template-columns: 1fr 43em;
        min-height: 0; }

      @include media-desktop-1440 {
        grid-template-columns: 1fr 50vw; }

      @include media-desktop-1600 {
        grid-template-columns: 1fr 39.06vw; }

      &-left {
        position: relative;

        display: none;

        @include media-tablet {
          display: block;
          height: 26.75em; }

        @include media-tablet-h {
          height: 100%; } }

      &-slide {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;

        // анимация временно выключена
        // opacity: 0

        // animation-name: header
        // animation-duration: 15s
        // animation-iteration-count: infinite

        img {
          width: 100%;
          height: 100%;

          object-fit: cover;
          object-position: center; }

        // анимация временно выключена
        // &:nth-child(1)
        //   animation-delay: 0s
        // &:nth-child(2)
        //   animation-delay: 3s
        // &:nth-child(3)
        //   animation-delay: 6s
        // &:nth-child(4)
        //   animation-delay: 9s
        // &:nth-child(5)
 }        //   animation-delay: 12s

      &-right {
        position: relative;

        display: flex;
        flex-direction: column;

        @include media-tablet {
          display: grid;
          grid-template-areas: "logo desc" "title title";
          grid-template-columns: repeat(2, 1fr);

          background-color: transparent;
          border-bottom-left-radius: 1.25em; }

        @include media-tablet-h {
          grid-template-rows: auto 1fr; }

        @include media-desktop {
 } }          // width: 45em

      &-logo {
        position: relative;
        z-index: 3;

        padding-top: 1em;
        padding-bottom: 1em;

        background-color: $red;
        border-bottom-left-radius: 1.25em;
        border-bottom-right-radius: 1.25em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        @include media-tablet {
          position: relative;
          padding: 0;

          grid-area: logo;
          margin-top: 0; }

        img {
          // width: 8.0625em
          height: 7.875em;
          height: 100%;
          display: block;

          @include media-tablet {
            height: 9.3125em;
            width: auto; }

          @include media-desktop-1600 {
            height: 6.1719vw; } } }

      &-desc {
        position: relative;
        align-items: center;
        grid-area: desc;
        display: flex;
        flex-direction: column;
        padding: 2em;
        padding-top: 3em;
        margin-top: -1em;
        background: $dark-grey;
        width: 100%;

        text-align: center;

        @include media-tablet {
          z-index: 2;
          font-size: 1.10em;
          text-align: left;
          margin: 0;
          padding: 3.625em 0em 3.625em 0em;
          &:before {
            content: '';
            position: absolute;
            left: -2rem;
            width: 5rem;
            background: $dark-grey;
            height: 100%; } }

        @include media-desktop-1600 {
          padding: 6.625em 3.5625em 6.625em 3.5625em;
          font-size: 0.875vw; }

        p {
          @include reset;

          display: block;
          margin-bottom: 0.5em;
          max-width: 14.1875rem;

          color: white;

          @include media-tablet {
            max-width: 15.1875rem; }
          @include media-desktop-1600 {
            max-width: 14.1875rem; }

          &:last-child {
            margin-bottom: 0; } } }

      &-title-block {
        grid-area: title;
        padding: 1.75em 1em;
        max-width: 24.375em;
        margin: 0 auto;
        background: #fff;

        @include media-tablet {
          position: relative;
          border-bottom-left-radius: 0.5rem;
          z-index: 2;
          max-width: none;
          padding: 4em 2.8125em;
          display: flex;
          flex-direction: column;
          text-align: left;
          width: initial; }

        @include media-tablet-h {
          padding: 6.1094vw 4.5141vw; }
        @include media-desktop-1440 {
          padding: 6.1094vw 4.5141vw; }
        @include media-desktop-1600 {
          padding: 10.1094vw 4.5141vw 4.625vw; } }

      &-date {
        @include fs18;

        font-weight: 800;
        color: $dark-grey;

        @include media-tablet {
          @include h24;
          font-size: 1.875em; }

        @include media-desktop {
          font-size: 2.25vw; } }

      &-title {
        color: $dark-grey;

        @include media-tablet {
          position: relative; }

        &-name {
          @include h48;

          margin-top: 0.166em;

          font-weight: 800;
          display: inline-block;
          text-transform: uppercase;

          @include media-tablet {
            @include h36;
            font-size: 94px;
            line-height: 84px;
            margin-top: 0.375vw; }
          @include media-desktop-1600 {
            font-size: 4.75vw; }
          span {
            color: $red; } }

        &-reg {
          position: relative;
          @include media-tablet {
            display: inline-block;
            left: 0.2em;
            top: -0.24em; }

          .btn {
            width: 100%;
            text-align: center;
            font-size: 0.375em;
            margin-top: 0.1em;
            @include media-tablet {
              font-size: 0.2em;
              margin-top: 0em; } } } } } } }

.header--netw {
  .header {
    &__main {
      margin-top: 2.5em;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;

      @include media-tablet {
        margin-top: 3.625em;
        padding-top: 2.5em;
        padding-bottom: 2.5em;

        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em; }

      @include media-desktop {
        padding-top: 5em;
        padding-bottom: 5em; } }

    &__title {
      @include reset;
      @include h24;

      @include media-tablet {
        @include h36; }

      @include media-desktop {
        @include h64; } } } }
