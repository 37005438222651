.about-netw {
  &__main {
    padding: 5em 0 0;

    color: #ffffff;

    background-color: $dark-grey;

    @include media-tablet {
      padding: 3.75em 0 2.5rem; }

    @include media-tablet-h {
      position: relative;
      padding: 6em 0 3.875em;

      background-color: $color-red;
      overflow: hidden; }

    @include media-desktop {
      padding: 6em 0 2.125em; } }

  &__cont {
    &--main {
      position: relative;

      display: grid;
      row-gap: 1rem;

      @include media-tablet {
        row-gap: 2rem; }

      @include media-tablet-h {
        grid-template-columns: 28.375rem 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 2.5em;
        row-gap: 2.5em; }

      @include media-desktop {
        grid-template-columns: 34.1875em 1fr; }

      &::before {
        @include media-desktop-1600 {
          content: "";

          position: absolute;
          left: -100em;
          top: -10em;
          bottom: -10em;

          width: 100em;

          background-color: $dark-grey; } } }

    &--footer {
      display: grid;
      row-gap: 2.25em;

      @include media-tablet {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 3.75em; }

      @include media-tablet-h {
        grid-template-columns: auto auto 1fr;
        column-gap: 5em; }

      @include media-desktop {
        display: flex;
        column-gap: 0; } } }

  &__bg {
    display: none;

    @include media-tablet-h {
      position: absolute;
      top: -6em;
      bottom: -3.875em;

      display: block;
      width: 46.9375em;

      background: $dark-grey;
      border-top-right-radius: 1rem; } }

  &__content {
    display: grid;
    row-gap: 1.5em;

    @include media-tablet-h {
      position: relative;
      z-index: 2;

      grid-column: 1;
      grid-row: 1;
      row-gap: 0.75em;
      margin-top: -3rem; } }

  &__title {
    @include reset;
    font-size: 2em;

    @include media-tablet {
      @include h48; }

    span {
      color: $red; } }

  &__desc {
    @include reset;
    @include p14;

    padding-bottom: 0.5rem;

    @include media-tablet {
      width: 30.75rem;
      padding-bottom: 0; }

    @include media-tablet-h {
      @include p18;
      width: 30rem; } }

  &__steps {
    display: grid;
    row-gap: 1rem;
    column-gap: 1.25rem;
    align-self: start;
    grid-template-areas: "buttons img" "divider divider" "content content";
    grid-template-rows: repeat(3, max-content);
    background-color: #ffffff;
    margin-left: -$mobile-padding;
    margin-right: -$mobile-padding;
    padding: 1.5625rem $mobile-padding;
    min-height: 20.325rem;

    @include media-tablet {
      grid-template-areas: "buttons buttons" "divider divider" "img content";
      grid-template-rows: repeat(2, max-content) 1fr;
      padding: 1.5rem 2.5rem 0;
      row-gap: 1.5rem;
      column-gap: 1.875rem;
      min-height: 17.25rem;
      margin: 0;

      border-radius: 1.25rem; }

    @include media-tablet-h {
      position: relative;
      z-index: 2;

      grid-column: 2;
      grid-row: 1 / span 2;
      row-gap: 1.125rem;
      column-gap: 1.125rem;
      padding: 1.5rem;
      padding-bottom: 0;
      min-height: 0;
      width: 29.1875rem;
      justify-self: end;

      box-shadow: 0.625em 0.625em 1.25em rgba(0, 0, 0, 0.25); }

    @include media-desktop {
      width: 36rem; }

    &-title {
      display: none;

      @include media-tablet-h {
        @include reset;
        font-size: 1.4em;
        font-weight: 700;

        display: block;
        position: absolute;

        top: -2em; }
      @include media-desktop {
        @include h24; } }

    &-buttons {
      @include lreset;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      grid-area: buttons;

      @include media-tablet {
        flex-direction: row;
        justify-content: start;
        column-gap: 3.625rem; } }

    &-img {
      width: 8.125rem;
      height: 7.5rem;
      grid-area: img;

      @include media-tablet {
        width: 11rem;
        height: 100%; }

      @include media-tablet-h {
        width: 9.375rem; }

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        object-position: center;

        @include media-tablet {
          object-position: center bottom; } } }

    &-btn {
      button {
        @include reset;
        @include h24;

        font-weight: 800;
        color: $dark-grey;

        background-color: transparent;
        border: none;
        opacity: 0.5;
        cursor: pointer;

        @include media-tablet {
          @include h36; }

        @include media-tablet-h {
          @include h24; }

        @include media-desktop {
          @include h36; } }

      &--active {
        button {
          color: $red;

          opacity: 1; } } }

    hr {
      @include reset;

      width: 100%;
      height: 2px;
      grid-area: divider;

      border: none;
      background-color: $dark-grey;

      @include media-tablet {
        background-color: $grey-80; } }

    &-content {
      grid-area: content;

      @include media-tablet {
        padding-bottom: 1.5rem; } }

    &-item {
      display: none;
      row-gap: 0.5em;

      &--active {
        display: grid; }

      &-title {
        @include reset;
        @include h24;

        color: $red;

        @include media-tablet-h {
          @include h24; } }

      &-desc {
        @include reset;
        @include fs18;

        color: $dark-grey;

        @include media-tablet-h {
          @include fs16; } } } }

  &__capabilities {
    @include h24;

    color: #ffffff;
    font-weight: 800;

    @include media-tablet {
      position: relative;

      width: max-content;

      &::after {
        content: "";

        position: absolute;
        right: -9rem;
        top: 0.5rem;

        width: 6.375rem;
        height: 4rem;

        background-image: url("../images/about-netw-arrow.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat; } }

    br {
      display: none;

      @include media-tablet {
        display: inline; } } }

  &__footer {
    padding: 2.5em 0 3.75em;

    color: $dark-grey;

    background-color: $dark-grey;

    @include media-tablet {
      padding: 3.75em 0;

      background-color: #ffffff; }

    @include media-tablet-h {
      padding: 2.125em 0; }

    &-item {
      display: grid;
      row-gap: 0.25em;

      text-align: center;
      @include media-desktop {
        margin-right: 9.375rem;
        width: 15.5rem; } }

    &-num {
      @include reset;
      @include h36;

      color: #ffffff;

      @include media-tablet {
        @include h48;

        color: $red; }

      @include media-tablet-h {
        @include h36; } }

    &-desc {
      @include reset;
      @include fs18;

      color: #ffffff;

      @include media-tablet {
        @include h24;

        font-weight: 400;
        color: $dark-grey; }

      @include media-tablet-h {
        @include fs18; } }

    &-btn {
      text-transform: uppercase;
      font-size: 0.9em;
      box-shadow: 0.2222em 0.2222em 0.6667em rgba(0, 0, 0, 0.15);

      @include media-tablet {
        @include h24;
        grid-column: 1 / span 2;

        justify-self: center; }

      @include media-tablet-h {
        grid-column: 3;
        width: 100%;
        max-width: 21.3889em;
        align-self: center;
        margin-left: auto; }

      @include media-desktop {
        margin-left: 0; } } } }
